import React from "react"
import { graphql } from "gatsby"
import BlogList from "../components/blog-list"
import { getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import DummyHero from "../components/dummy-hero"
import Pagniation from "../components/pagination"
import logo from "../images/favicon.png"
import Meta from "../components/meta"

const BlogListTemplate = ({ data, pageContext }) => {
  const { previousPagePath, nextPagePath, pageNumber, slug, name } = pageContext

  const next = nextPagePath ? `${nextPagePath}/` : undefined
  const prev = previousPagePath ? `${previousPagePath}/` : undefined

  let meta = {
    title: `${slug}`,
    description: `Read the Blogs posted by Digital Laboratory Myanmar. Page ${pageNumber}`,
    url: `https://www.digitallaboratory.net/${slug}/${
      pageNumber > 1 ? `${pageNumber}/` : ""
    }`,
    image: logo,
  }

  const blogs =
    data.allStrapiBlog?.edges.map(e => {
      const tags = e.node?.tags ?? []
      const gatsbyImage = getImage(e.node.cover.localFile)
      const alt = e.node.cover.alternativeText
      const cover = { alt, gatsbyImage }
      return { ...e.node, tags, cover }
    }) ?? []

  return (
    <Layout>
      <Meta meta={meta} next={next} prev={prev} />
      {/* TODO reaplace */}
      <DummyHero title="Our Blogs" subtitle={name} />
      <div className="containerWrapper my-[50px]">
        <div className="">
          <BlogList blogs={blogs} isMain={true} />
        </div>
        <div className="mt-[50px]">
          <Pagniation pageContext={pageContext} />
        </div>
      </div>
    </Layout>
  )
}

export const Head = ({ pageContext }) => {
  // console.log("blogs: ", blogs);

  const { previousPagePath, nextPagePath, pageNumber, slug, name } = pageContext

  const next = nextPagePath ? `${nextPagePath}/` : undefined
  const prev = previousPagePath ? `${previousPagePath}/` : undefined

  let meta = {
    title: `${slug}`,
    description: `Read the Blogs posted by Digital Laboratory Myanmar. Page ${pageNumber}`,
    url: `https://www.digitallaboratory.net/${slug}/${
      pageNumber > 1 ? `${pageNumber}/` : ""
    }`,
    image: logo,
  }
  return <Meta meta={meta} next={next} prev={prev} />
}

export const query = graphql`
  query TagBlogList($skip: Int!, $limit: Int!, $slug: String) {
    allStrapiBlog(
      filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          date(formatString: "MMMM D, YYYY")
          slug
          tags {
            name
            slug
          }
          cover {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.77, width: 450, quality: 100)
              }
            }
          }
        }
      }
    }
  }
`

export default BlogListTemplate
